<script>
  import Peg from "./Peg.svelte";

  export let row;
  export let rownum;
</script>

<div class="rownum">{rownum}</div>
<!-- <div class="peg" bind:class={color}/> -->
{#each row as color, idx}
  {#if idx == 4}
    <div />
  {:else}
    <Peg color={color} />
  {/if}
{/each}
<div />
