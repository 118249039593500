<script>
export let color

function getcolor(color){
    if (color===0){
        return ""
    }else{
        return "background:"+color
    }
}
</script>

<!-- <div class="peg" bind:class={color}/> -->

<!-- <div class="peg" id={pegId} style={getcolor(color)}/> -->
<div class="peg" style={getcolor(color)}/>