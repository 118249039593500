<style>
/* Navbar */
.navbar {
	/* display: flex; */
	justify-content: space-between;
	align-items: center;
  padding: 0.7rem 2rem;
  font-size: x-large;
	z-index: 1;
	width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top:0;
  background: #222;
  color: #fff;
  display: block;
}

.controls{
  margin: auto;
  right: 0;
  text-align: end;
}

</style>

<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let colors;

  const onclick = color => dispatch("clickpeg", color);
</script>

<div class="navbar">
  <h1>Mastermind</h1>
  <div class="controls">
    {#each colors as color}
      <div
        class="peg"
        style="background-color:{color}"
        on:click={onclick(color)} />
    {/each}
  </div>
</div>
