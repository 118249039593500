<style>
.card{
  padding: 1rem;
  border: #000 2px solid;
  /* margin: 1rem; */
  text-align: center;
  /* width: fit-content; */
  font-size: xx-large;
  z-index: 1;
  position: relative;
}
.card-wrong{
    background-color: #fb3;
}
.card-right{
    background-color: #29c842;
}

.btn {
  display: inline-block;
  background: #000;
  color: #ccc;  
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}
.btn:hover{
  background: #090;
  color: #000;
  border: #000 2px solid;
}

</style>

<script>
  import Navbar from "./Navbar.svelte";
  import Fila from "./Fila.svelte";
  import Peg from "./Peg.svelte";
  import {fly,fade} from 'svelte/transition';

  const NROWS=10;

  function compare(value, guess) {
    console.log("With draw=", value, "and guess", guess);
    let perfect = 0;
    let approx = 0;
    for (let n = 0; n < 4; n++) {
      // check if actually it was filled
      //  for now not possible otherwise!
      if (guess[n] !== 0) {
        if (guess[n] === value[n]) {
          perfect++;
        } else if (value.includes(guess[n])) {
          approx++;
        }
      }
    }
    // Bug: if color repeats, it will lead to issues
    console.log("There are", perfect, "perfect and", approx, "colors");
    return [perfect, approx];
  }

  let rows;
  let idx = 0;
  let perfect = 0;
  let approx = 0;
  let draw = [0, 0, 0, 0];

  function restart() {
    let temp=[]
    for(let n=0;n<NROWS;n++)
      temp.push([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    rows = temp
    idx = 0;
    perfect = 0;
    approx = 0;
    draw = draw.map(
      // () => colors[2]
      () => colors[Math.floor(Math.random() * colors.length)]
    );
  }

  const colors = [
    "#555555",
    "#ffffff",
    "#ff0000",
    "#00bb00",
    "#0000ff",
    "#ffff00"
  ];

  const clickpeg = color => {
    // players = players.filter(player => player.name !== e.detail);
    let temp = rows;
    // let perfect, approx;
    temp[Math.floor(idx / 8)][idx++ % 8] = color.detail;
    if (idx % 4 == 0) {
      [perfect, approx] = compare(draw, rows[Math.floor(idx / 8)].slice(0, 4));
      for (let n = 0; n < perfect; n++)
        temp[Math.floor(idx / 8)][(idx++ % 8) + 1] = colors[0];
      for (let n = 0; n < approx; n++)
        temp[Math.floor(idx / 8)][(idx++ % 8) + 1] = colors[1];
      idx += 4 - perfect - approx;
    }
    rows = temp;
  };

  restart();

</script>

<main>
  <Navbar {colors} on:clickpeg={clickpeg} />

  {#if perfect === 4}
  <div class="card card-right" in:fly={{y:500,duration:2000}} out:fade>
    <div>🎉🎉🎉🎉🎉🎉</div>
    <div>You Win!!</div>
    <div>🎉🎉🎉🎉🎉🎉</div>
    <button class="btn btn-dark" on:click={restart}>Restart</button>
  </div>
  {:else if idx >= NROWS * 8}
  <div class="card card-wrong" in:fly={{y:500,duration:2000}} out:fade>
    <div>💩💩💩💩💩💩</div>
    <div>You Lost</div>
    <div>
    {#each draw as pegcolor}
        <Peg color={pegcolor} />
    {/each}
    </div>
    <div>💩💩💩💩💩💩</div>
    <button class="btn btn-dark" on:click={restart}>Restart</button>
  </div>
  {/if}

  <div class="container">
    <div class="board">
      {#each rows as row, idx}
        <Fila {row} rownum={idx} />
      {/each}
    </div>
  </div>
</main>
